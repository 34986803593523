export const ARTIST =  'Artist';
export const BUSINESS = 'Business';
export const PERSONAL_BRANDING = 'Personal Branding';
export const SPORTS = 'Sports';
export const GAME = 'Game';
export const FOODS = 'Foods';
export const OUTDOOR = 'Outdoor';
export const ANIMAL_AND_PET = 'Animal & Pet';
export const CAFE_AND_BAR = 'Cafe & Bar';
export const REALESTATE = 'Real Estate';
export const BOTANIC = 'Botanic';
export const WORKOUT = 'Workout';
export const ASTROLOGY = 'Astrology';
export const ORGANIC = 'Organic';
export const YOGA = 'Yoga';
