import { css } from '@mui/material';
import classNames from 'classnames';
import { HTMLAttributes } from 'react';

import { CategoryButton, CategoryItemSkeleton } from '~/src/components/business/CategoryButton';

interface Item {
  id: string;
  title: string;
  icon: string;
}

export interface CategoriesListProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onClick'> {
  items: Item[];
  activeId?: string;
  loading?: boolean;
  onClick?: (item: Item) => any;
}

export const CategoriesList = ({ activeId, items, loading, onClick, ...others }: CategoriesListProps) => {
  return (
    <div
      { ...others }
      className={classNames(
        'CategoriesList',
        others.className
      )}
      css={css`
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        min-height: 36px;
        column-gap: 8px;
        row-gap: 10px;
      `}
    >
      {
        items.map((item, index) => {
          return (
            <div
              key={index}
              className="CategoriesList__Item"
            >
              <CategoryButton
                selected={item.id === activeId}
                title={item.title}
                icon={item.icon}
                css={css`
                  height: 36px;
                `}
                onClick={() => {
                  onClick && onClick(item);
                }}
              />
            </div>
          );
        })
      }
      {
        loading &&
          <CategoryLoader />
      }
    </div>
  );
};

const CategoryLoader = () => {
  return (
    <>
      {
        Array(6).fill('').map((_, index) => {
          return (
            <li
              key={index}
              className="TemplatePanel__Category"
            >
              <CategoryItemSkeleton />
            </li>
          );
        })
      }
    </>
  );
};
