import { css } from '@emotion/react';
import { Typography, useTheme  } from '@mui/material';
import classNames from 'classnames';
import Image from 'next/image';
import { HTMLAttributes, MouseEventHandler, useMemo } from 'react';

import { ComponentProps } from '~/src/types/common';

import { Skeleton } from '../../app/Skeleton';

export interface CategoryItemProps extends HTMLAttributes<HTMLButtonElement> {
  selected?: boolean;
  title: string;
  icon: string;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const CategoryButton = (
  { selected, title, icon, disabled, onClick, ...others }: CategoryItemProps
) => {
  const theme = useTheme();
  const active = useMemo(() => selected ?? false, [selected]);
  const isDisabled = useMemo(() => disabled ?? false, [disabled]);

  return (
    <button
      { ...others }
      aria-label={title}
      className={classNames(
        others.className,
        'CategoryItem',
        active && 'CategoryItem-Active'
      )}
      css={css`
        display: flex;
        align-items: center;
        background-color: ${active ? theme.app.colors['neutral-color-04'] : theme.app.colors['neutral-color-09']};
        padding: 0 16px 0 12px;
        height: 36px;
        border: 1px solid transparent;
        border-radius: 8px;
        cursor: pointer;
        &:disabled {
          cursor: default;
        }
      `}
      disabled={isDisabled}
      onClick={onClick}
    >
      {
        icon &&
          <Image
            src={icon}
            alt={title}
            width={24}
            height={24}
            css={css`
              margin-right: 8px;
            `}
            onDragStart={(event) => event.preventDefault()}
          />
      }
      {
        title &&
          <Typography
            className={classNames(
              'CategoryItem__Name'
            )}
            css={css`
              white-space: nowrap;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              color: ${active ? theme.app.colors['neutral-color-10'] : theme.app.colors['pure-black']};
            `}
          >
            { title }
          </Typography>
      }
    </button>
  );
};

type CategoryItemSkeletonProps = ComponentProps

export const CategoryItemSkeleton = (props: CategoryItemSkeletonProps) => {
  return (
    <div
      className={classNames(
        'CategoryItemSkeleton',
        props.className
      )}
      style={props.style}
      css={css`
        width: 100px;
        height: 36px;
        border-radius: 6px;
        overflow: hidden;
      `}
    >
      <Skeleton
        type="text"
        css={css`
          width: 100%;
          height: 100%;
        `}
      />
    </div>
  );
};
